// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, Typography } from '@mui/material';

declare global {
  interface Window {
    google: any;
  }
}

interface PlaceDetail {
  name: string;
  address: string;
  city: string;
  county?:string;
  state: string;
  country: string;
  postalCode: string;
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const AddressAutocomplete = ({onPlaceSelect, variant, sx,required}: {onPlaceSelect: any; variant?: string; sx?: any,required?:boolean}) => {
  const [inputValue, setInputValue] = useState('');
  const [touched, setTouched] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<PlaceDetail | null>(null);
  const [options, setOptions] = useState<PlaceDetail[]>([]);
  const loaded = useRef(false);
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    let autocompleteService: any;
    if (window.google) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }

    if (autocompleteService && inputValue !== '') {
      autocompleteService.getPlacePredictions({ input: inputValue, componentRestrictions: { country:["US", "CA", "AU"] } }, (predictions: any) => {
        if (predictions) {
          setOptions(predictions.map((prediction: any)=> {
            return {
              name: prediction.structured_formatting.main_text,
              address: prediction.description,
              city: '',
              county:'',
              state: '',
              country: '',
              postalCode: '',
              place_id: prediction.place_id
            }
          }));
        } else {
          setOptions([]);
          setSelectedAddress(null);
          onPlaceSelect({
              name:'',
              address: '',
              city: '',
              county:'',
              state: '',
              country: '',
              postalCode: '',
          })
        }
      });
    }
    else{
      setSelectedAddress(null);
      onPlaceSelect({
        name:'',
        address: '',
        city: '',
        county:'',
        state: '',
        country: '',
        postalCode: '',
      })
    }
  }, [inputValue]);

  function getCustomFormattedAddress(place) {
    // Define the types of address components to exclude
    const excludeTypes = ["locality", "administrative_area_level_1", "country", "postal_code"];
  
    // Filter out excluded components and map to their long names
    const filteredComponents = place.address_components
      .filter(component => !excludeTypes.some(type => component.types.includes(type)))
      .map(component => component.long_name);
  
    // Join the components with a space or any other preferred separator
    const customFormattedAddress = filteredComponents.join(", ");
  
    return customFormattedAddress;
  }

  const fetchPlaceDetails = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId }, (place: any, status: any) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        let addressComponents = place.address_components;
        let address: PlaceDetail = {
          address: getCustomFormattedAddress(place),
          name: place.name,
          city: '',
          state: '',
          country: '',
          postalCode: '',
          county:'',
        };

        addressComponents.forEach((component: any) => {
          const types = component.types;
          if (types.includes("locality")) {
            address.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            address.state = component.long_name;
          } else if (types.includes("country")) {
            address.country = component.long_name;
          } else if (types.includes("postal_code")) {
            address.postalCode = component.long_name;
          } else if (types.includes("administrative_area_level_2")) {
            address.county = component.long_name;  
          }
        });
        setSelectedAddress(address);
        onPlaceSelect(address)
      }
    });
  };

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <>
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      getOptionLabel={(option) => option.address}
      renderInput={(params) => (
        <TextField
        {...params}
        label={"Address"}
        InputProps={{ ...params.InputProps, type: 'search', style: { borderRadius: 8, fontSize: '18px' } }}
        variant={variant || "outlined"}
        sx={{
          '& .MuiFormLabel-root': {
            fontSize: '18px',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            fontSize: '18px',
          },
          }}
          onBlur={handleBlur}
          required={required}
          />
        )}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(_, value) => {
          if (value && value.hasOwnProperty('place_id')) {
            fetchPlaceDetails(value.place_id);
          }
        }}
        PaperComponent={(paperProps: any) =>
          <Paper square={true} {...paperProps.innerProps}>
          {paperProps.children}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '4px 8px',
          }}>
            <img src={'static/images/logos/powered_by_google_on_white.png'} alt="Powered by Google" />
          </div>
        </Paper>
      }
      />
      {required && touched && !selectedAddress && (
        <Typography  sx={{ fontSize: '18px !important', mt: 1,color:"#e71414" }}>
          Must be valid Google address
        </Typography>
      )}
      </>
  );
};

export default AddressAutocomplete;