import React, { useEffect } from "react";

interface SkipifyIntegrationProps {
  cartRefId: string;
}

declare global {
  interface Window {
    skipify: any;
  }
}

const SkipifyIntegration: React.FC<SkipifyIntegrationProps> = ({ cartRefId }) => {
  useEffect(() => {
    const initializeSkipify = () => {
      if (typeof window.skipify !== "undefined") {
        console.log("Skipify SDK is loaded.");

        // Initialize the SDK
        const skipifyClient = new window.skipify({
          merchantId: "007e60ee-442c-432b-85c6-8e0860875bb0",
        });

        // SDK options
        const options = {
          onClose: (myReference: string, success: boolean) => {
            console.log("On close UI callback triggered");
          },
          onApprove: (myReference: string, data: any) => {
            console.log("On approve UI callback triggered");
            console.log(data);
          },
          textColor: "#FEFEFE",
          bgColor: "#1E1E1E",
          bgHoverColor: "#444444",
          logoPlacement: "below",
          buttonLabel: "Pay Now",
          email: "sonny.elhamahmy@gmail.com",
          phone: "4045551212",
        };

        // Clear existing content inside `skipify-pay-checkout-container`
        const container = document.getElementById("skipify-pay-checkout-container");
        if (container) {
          container.innerHTML = ""; // Prevent duplicate button rendering
        }

        // Render checkout button dynamically
        skipifyClient
          .button(cartRefId, options)
          .render(document.getElementById("skipify-pay-checkout-container"));
      } else {
        console.error("Skipify SDK is not loaded.");
      }
    };

    // Check if the script is already loaded
    const scriptId = "skipify-sdk-script";
    let script = document.getElementById(scriptId) as HTMLScriptElement | null;

    if (!script) {
      // Add the Skipify SDK script if it doesn't already exist
      script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://stagecdn.skipify.com/sdk/checkoutsdk.js";
      script.async = true;
      script.onload = initializeSkipify;
      document.body.appendChild(script);
    } else if (typeof window.skipify !== "undefined") {
      // If the script already exists and SDK is loaded, initialize directly
      initializeSkipify();
    }

    return () => {
      // Optionally clean up if needed
    };
  }, [cartRefId]);

  return (
    <div id="skipify-pay-checkout-container" />
  );
};

export default SkipifyIntegration;
