import React from 'react';

export const generateQuery = (query: any) => {
    const url = ''

    return url + Object.keys(query).reduce((accumulator, key, index, array) => {
        if (query[key] === '' || query[key] === null || (query[key] !== null && query[key].toString().trim() === '')) {
            return accumulator
        } else {
            return accumulator + `${accumulator.includes('?') ? '&' : '?'}${key}=${query[key]}`
        }

    }, '')
};

const time: number = 2000;
export const inactivityLogout = (times: number) => {
    resetTimer();
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keypress", resetTimer);

};

const resetTimer = () => {
    clearTimeout(time);
}

export const inactivityLogoutRemoveListener = () => {
    document.removeEventListener("mousemove", resetTimer);
    document.removeEventListener("keypress", resetTimer);
    clearTimeout(time);
};

export const searchName = (name: string, searchValue: string): boolean => {
    if (searchValue && name)
        return (name.toLowerCase().trim().includes(searchValue.toLowerCase()));
    else
        return false;
}

export const showString = (stringValue: string | undefined | null): string => {
    return !stringValue ? '' : stringValue;
}

export const showHtml = (html: string) => {
    if (!html) {
        return '';
    }

    const paragraphTags = /<\/p>/gim;
    const allTagsExceptBreak = /<(?!br|\/br).+?>/gim;

    const sanitizedHtml = html
        .replace(paragraphTags, '<br>')
        .replace(allTagsExceptBreak, '');

    return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

export const normalizePhoneInput = (value: string, previousValue: string) => {
    if (!value) {
        return value;
    }

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }

        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        }

        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }

    return value;
};

export const replaceComma = (str: string | undefined) => {
    if (typeof str === 'string') {
        const pattern = /^(.*?),/;
        return str.replace(pattern, "$1");
    }
    return str; // Return str unchanged if it's not a string
}