import { getDomainName } from "../domain-name";
import IContact from "../models/IContact";
import IOrder from "../models/IOrder";
import { IUser } from "../models/IUser";

export const contactAPIs = {
    contactPost: (contact: IContact) => ({
        url: 'Contact',
        method: 'post',
        body: contact,
        baseURL: '',
        apiEndPoint: '/Contact/Guest',
    }),
    getAllSavedAddresses: () => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/user-addresses`,
    }),
    createNewAddress: (body: any) => ({
        method: 'post',
        body,
        token: '',
        apiEndPoint: `/user-addresses`,
    }),
    udpateAddress: (body: any, id: any) => ({
        method: 'put',
        body,
        token: '',
        apiEndPoint: `/user-addresses/${id}`,
    }),
    deleteAddress: (id: any) => ({
        method: 'delete',
        body: '',
        token: '',
        apiEndPoint: `/user-addresses/${id}`,
    }),
    restaurantContact:(body: any)=>({
        method: 'post',
        body,
        token: '',
        apiEndPoint: `/guest/restaurant-email`,
    })
};

export const orderAPIs = {
    orderPost: (body: IOrder) => ({
        url: 'Order',
        method: 'post',
        body,
        baseURL: '',
        apiEndPoint: '/Order/Guest',
    }),
    orderPut: (body: IOrder, placeOrder: boolean = false) => ({
        url: 'Order/',
        method: 'put',
        body,
        baseURL: '',
        apiEndPoint: placeOrder
            ? `/Order/Guest/place/${body.id}`
            : `/Order/Guest/${body.id}`,
    }),
    orderItemDelete: (id: number) => ({
        url: 'OrderItem/',
        method: 'delete',
        baseURL: '',
        apiEndPoint: `/OrderItem/${id}`,
    }),
    orderGet: (id: number) => ({
        url: 'Order/',
        method: 'get',
        baseURL: '',
        apiEndPoint: `/Order/${id}`,
    }),
    onlineOrdersEnabledGet: (date: string | null = null) => ({
        url: 'Order/',
        method: 'get',
        baseURL: '',
        apiEndPoint: date === null
            ? '/Order/OnlineOrdersEnabled'
            : `/Order/OnlineOrdersEnabled?dateTime=${date}`,
    }),
    clearUnavailableItems: (orderId: number) => ({
        url: 'Order/Guest/',
        method: 'post',
        baseURL: '',
        apiEndPoint: `/Order/${orderId}/ClearUnavailableItems`,
    }),
    getAllOldOrders:()=>({
        url: 'Order/',
        method: 'get',
        baseURL: '',
        apiEndPoint: `/orders/order-history`,
    }),
};

export const orderPaymentAPIs = {
    placePayment: {
        url: 'Order/',
        method: 'get',
        id: '',
        token: 'string',
        baseURL: '',
        get apiEndPoint() {
            return this.url + this.id + '/PayInFullCardPaymentUrl';
        },
        set addParams(id: string) {
            this.id = id
        },
    },
};

export const authorize = {
    registerCustomer: (organizationId: string) => ({
        url: '/RegisterCustomer',
        method: 'post',
        id: '',
        baseURL: 'Auth',
        apiEndPoint: `/${organizationId}/RegisterCustomer`,
    }),
    registerUser: (payload: IUser[]) => ({
        method: 'post',
        id: '',
        body: payload,
        baseURL: 'register',
        apiEndPoint: `/owner/users/register`,
    }),
    getUserByEmailId: (email: string) => ({
        method: 'post',
        id: '',
        body: '',
        baseURL: 'register',
        apiEndPoint: `/owner/users/${email}`,
    }),
    assignUserToOrg: (payload: any) => ({
        method: 'post',
        id: '',
        body: payload,
        baseURL: 'assignRole',
        apiEndPoint: `/auth0/add-member-to-org/guest-role`,
    }),
};

export const organizationsAPIs = {
    getOrganizations: () => {
        const urlParams = new URLSearchParams(window.location.search);
        const domainURL = urlParams.get('domainURL');
        return ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/organizations?domainName=${getDomainName()}`,
    })},
    getOrganizationsSettings: (organizationID: number | string) => {
        return ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/organization-settings/organization/${organizationID}`,
    })},
    getOrganizationOrdersOpenSchedule: (organizationID: number | string) => {
        return ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/orders-open-schedule/organization/${organizationID}`,
    })},
    getUserDetails: () => {
        return ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/users/profile`,
    })},
    udpateUserDetails: (body: any) => {
        return ({
        method: 'put',
        body,
        token: '',
        apiEndPoint: `/users/profile`,
    })},
    getEventPicklistOptions: (body: any) => ({
        method: 'get',
        body: body,
        token: '',
        apiEndPoint: `/pick-list-options/${body.pickListName}?organizationId=${body.organizationId}`,
    }),
};

export const itemsAPIs = {
    getMenus: (organizationID: number | string) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/${organizationID}/menus`,
    }),
    getMenuCategories: (organizationID: number | string, menuId: number) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/${organizationID}/menus/${menuId}`,
    }),
    getOrderHistoryItems: () => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/orders/order-history`,
    }),
    menuItemSearch: (value: string) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/menu-items-search?${value}`,
    }),
};

export const checkoutAPIs = {
    createGuestOrder: (payload: any) => ({
        method: 'post',
        body: payload,
        token: '',
        apiEndPoint: `/guest/orders`,
    }),
    getGuestOrder: (orderId: string) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/guest/orders/${orderId}`,
    }),
    getCalculatedValues: (body: any) => ({
        method: 'post',
        body: body,
        token: '',
        apiEndPoint: `/guest/orders/calculate-payment`,
    }),
    getUndeliverablePostalCodes: (organizationID: number | string) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/delivery-zones/undeliverable?organizationId=${organizationID}`,
    }),
    getCalculatedEstimatedTime: (body: any) => ({
        method: 'post',
        body: body,
        token: '',
        apiEndPoint: `/guest/orders/calculate-estimated-time`,
    }),
};

export const paymentAPIs = {
    getCardDetails: (payload: any) => ({
        method: 'post',
        body: payload,
        token: '',
        apiEndPoint: `/payments/payment/cardbrandcheck`,
        isHPay: true,
    }),

    getSetupIntentDetail: (setupParameters: any) => ({
        method: 'get',
        body: '',
        token: '',
        apiEndPoint: `/completegraze?pi_guid=${setupParameters.pi_guid}&totalAmount=${setupParameters.totalAmount}&tipAmount=${setupParameters.tipAmount}&acct_id=${setupParameters.acct_id}&setup_intent=${setupParameters.setup_intent}&setup_intent_client_secret=${setupParameters.setup_intent_client_secret}`,
        isHPay: true,
    }),
    
    postPayment: (payload: any, processorType: any) => ({
        method: 'post',
        body: payload,
        token: '',
        apiEndPoint: `/payments`,
        isHPay: true,
        processorType : processorType
    }),

    postCreatePayLink: (payload: any, processorType: any) => ({
        method: 'post',
        body: payload, 
        token: '',
        apiEndPoint: `/payment-link/payment`,
        isHPay: true,
        processorType : processorType
    }),
};